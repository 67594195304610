.select {
  width: 100%;
  position: relative;
  padding-top: 7px;
  cursor: default;
}

.select:focus:not(.isDisabled) {
  outline: none;
}

.iconWrapper {
  position: absolute;
  right: 1px;
  top: calc(0px + 6px);
  padding: 10px 14px;
}
.select:focus:not(.isDisabled) .iconWrapper svg {
  transform: rotateZ(-180deg);
  transform-origin: center;
}
.iconWrapper svg {
  width: 11px;
  height: auto;
  transition: 0.2s all linear;
}

.iconWrapper svg path {
  fill: #959595;
}

.select:focus:not(.isDisabled) .iconWrapper svg path {
  fill: black;
}

.valueContainer {
  font-size: 16px;
  border: 1px solid rgba(149, 149, 149, 0.4);
  padding: 11px 14px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding-right: 30px;
  min-height: 43px;
}

.select:focus:not(.isDisabled) .valueContainer {
  border-color: black;
}

.error .valueContainer {
  border-color: var(--color-red);
}

.emptyValue {
  color: #c1c1c1;
}

.error .emptyValue {
  color: var(--color-red);
  font-weight: 300;
}

.optionList {
  padding: 10px 0;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: -1px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  background: white;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 13px -8px rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}

.select:not(:focus) .optionList,
.isDisabled .optionList {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-30px) scaleY(0.8);
  background-color: white;
}

.select:focus:not(.isDisabled) .optionList {
  transition: 0.2s transform ease;
}

.option {
  padding: 10px 15px;
  cursor: default;
}

@media (pointer: fine) {
  .option:hover {
    background: #f4f4f4;
  }
}

.option.selected {
  font-weight: 500;
}

.label {
  z-index: 1;
  position: absolute;
  display: flex;
  gap: 2px;
  font-size: 12px;
  left: 13px;
  top: 0;
  padding: 0 2px;
  background: white;
  max-width: calc(100% - 13px);
}
.labelText {
  flex-shrink: 0;
  flex-grow: 1;
  display: block;
  white-space: nowrap;
  width: calc(100% - 5px);
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.error .label {
  color: var(--color-red);
}

.isDisabled .label {
  color: #959595;
}

.requiredIndicator {
  color: var(--color-red);
  font-size: 12px;
}
